angular.module('services')

.service('wsApiService', function($q, $log) {
  if (location.protocol == 'https:') {
    let API = `wss://${location.host}/api/ws`;
  } else {
    let API = `ws://${location.host}/api/ws`;
  }

  let websocket = new ReconnectingWebSocket(API);
  let callbacks = {};

  websocket.onopen = function(event) {
    console.log(`API (${API}) connected`);
    console.log(`API (${API}) resending cached requests...`);
    return (() => {
      let result = [];
      for (let id in callbacks) {
        let callback = callbacks[id];
        let item;
        if (callback.sent === false) {
          try {
            websocket.send(angular.toJson(callback.request));
            item = callback.sent = true;
          } catch (error) {
            item = callback.sent = false;
          }
        }
        result.push(item);
      }
      return result;
    })();
  };

  websocket.onerror = function(event) {
    console.log(`API (${API}) error`, event);
    for (let id in callbacks) {
      let callback = callbacks[id];
      callback.reject("websocket closed");
    }
    return callbacks = {};
  };

  websocket.onclose = event => console.log(`API (${API}) close`, event);

  websocket.onmessage = function(event) {
    let msg = angular.fromJson(event.data);
    msg.body = Base64.decode(msg.body);
    if (angular.isDefined(callbacks[msg.id])) {
      let callback = callbacks[msg.id];
      delete callbacks[msg.id];
      if (msg.cmd === 'error') {
        $log.error(msg.body);
        callback.reject(msg);
      } else {
        msg.body = angular.fromJson(msg.body);
        callback.resolve(msg);
      }
    } else {
      $log.error('Unhandled message: %o', msg);
    }
  };

  let requestId = 0;

  let getRequestId = () => requestId++;

  let call = function(cmd, body) {
    let deferred = $q.defer();
    let request = {
      id: getRequestId(),
      cmd,
      body: Base64.encode(angular.toJson(body))
    };
    deferred.request = request;
    deferred.sent = false;
    callbacks[request.id] = deferred;
    try {
      websocket.send(angular.toJson(request));
      deferred.sent = true;
    } catch (e) {
      deferred.sent = false;
    }
    return deferred.promise;
  };

  this.transactions = function(bookName, itemType, code) {
    let input = {
      bookName: bookName,
      itemType: itemType,
      code: code,
    };
    return $q((resolve, reject) =>
      call("transactions", input)
      .then(resp => resolve(resp.body), resp => reject(resp.body))
    );
  };

  this.summary = function(bookName) {
    let input = {
      bookName: bookName
    };
    return $q((resolve, reject) =>
      call("summary", input)
      .then(resp => resolve(resp.body), resp => reject(resp.body))
    );
  };

  this.books = () =>
    $q((resolve, reject) =>
      call("books")
      .then(resp => resolve(resp.body), resp => reject(resp.body))
    );

  this.user = () =>
    $q((resolve, reject) =>
      call("user")
      .then(resp => resolve(resp.body), resp => reject(resp.body))
    );

  this.info = () =>
    $q((resolve, reject) =>
      call("info")
      .then(resp => resolve(resp.body), resp => reject(resp.body))
    );

  return this;
});
