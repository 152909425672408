angular.module('directives')

/**
 * numeral directive
 *
 * References:
 * - numeral: http://numeraljs.com/
 */

.directive('numeralDirective', () =>
  ({
    restrict: 'A',
    require: 'ngModel',
    scope: {
      numeralFormat: '@',
      numeralScale: '@',
    },
    link(scope, element, attrs, ngModelController, transcludeFn) {
      var scale = 0;
      if (scope.numeralScale) {
        var scaleNum = numeral(scope.numeralScale).value();
        if (Number.isInteger(scaleNum)) {
          scale = scaleNum
        }
      }
      ngModelController.$parsers.push(data => {
        return numeral(data).value() * Math.pow(10, scale);
      });
      ngModelController.$formatters.push(data => {
        if (scope.numeralFormat) {
          return numeral(data * Math.pow(10, -scale)).format(scope.numeralFormat)
        } else {
          return data * Math.pow(10, -scale);
        }
      });
    }
  })
);
