angular.module('services')

.factory('transactionService', function($http, $q, $httpParamSerializer) {

  this.list = function(book_name) {
    args = {}
    if (book_name != null) {
      args["book_name"] = book_name
    }
    return $q((resolve, reject) =>
      $http.get("/api/transactions?" + $httpParamSerializer(args))
      .then(resp => resolve(resp.data), resp => reject(resp.data))
    );
  };

  this.create = obj =>
    $q((resolve, reject) =>
      $http.post("/api/transactions", obj)
      .then(resp => resolve(resp.data), resp => reject(resp.data))
    );

  this.update = (name, obj) =>
    $q((resolve, reject) =>
      $http.put(`/api/transactions/${name}`, obj)
      .then(resp => resolve(resp.data), resp => reject(resp.data))
    );

  this.delete = name =>
    $q((resolve, reject) =>
      $http.delete(`/api/transactions/${name}`)
      .then(resp => resolve(resp.data), resp => reject(resp.data))
    );

  return this;
});
