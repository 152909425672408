angular.module('directives')

.directive('accountBookCashDirective', () =>
  ({
    restrict: 'AE',
    templateUrl: '/static/templates/account-book-cash.27459702.html',
    controller: 'AccountBookCashController',
    scope: {}
  })
);
