angular.module('directives')

/**
 * focusMe directive
 *
 * When a Model is opened, set focus on a predefined <input> insdie this Modal.
 *
 * References:
 * - https://stackoverflow.com/a/14837021/288089
 */

.directive('focusMe', ['$timeout', '$parse', function($timeout, $parse) {
  return {
    restrict: 'A',
    scope: {
      focusMe: '='
    },
    link: function(scope, elt) {
      scope.$watch('focusMe', function(val) {
        if (val) {
          elt[0].focus();
        }
      });
    }
  };
}]);
