angular.module('services')

.factory('userService', function($http, $q) {

  this.getUser = () =>
    $q((resolve, reject) =>
      $http.get("/api/user")
      .then(resp => resolve(resp.data), resp => reject(resp.data))
    );

  this.signOut = () =>
    $q((resolve, reject) =>
      $http.get("/signout")
      .then(resp => resolve(resp.data), resp => reject(resp.data))
    );

  this.loadOptions = function() {
    let optionsStr = localStorage.getItem("options");
    if (!optionsStr) {
      return {};
    }
    return JSON.parse(optionsStr);
  };

  this.saveOptions = options => localStorage.setItem("options", JSON.stringify(options));

  this.info = () =>
    $q((resolve, reject) =>
      $http.get("/api/info")
      .then(resp => resolve(resp.data), resp => reject(resp.data))
    );

  return this;
});
