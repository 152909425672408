angular.module('directives')

.directive('accountBookDirective', () =>
  ({
    restrict: 'AE',
    templateUrl: '/static/templates/account-book.79ae4148.html',
    controller: 'AccountBookController',
    scope: {
      accountBook: '='
    }
  })
);
