angular.module('directives')

.directive('accountBookStockDirective', () =>
  ({
    restrict: 'AE',
    templateUrl: '/static/templates/account-book-stock.e6975237.html',
    controller: 'AccountBookStockController',
    scope: {}
  })
);
