angular.module("controllers")

.controller("AccountBookController", function($rootScope, $scope, $interval, $uibModal, $filter, $state, $location, $window, accountBookService, wsApiService) {
  $scope.inited = false;
  $scope.summary = {};

  $scope.refresh = () =>
    wsApiService.summary($scope.accountBook.metadata.name)
    .then(function(summary) {
      summary.today_delta = summary.today_value - summary.yesterday_value;
      if (summary.yesterday_value) {
        summary.today_delta_percent = summary.today_delta / summary.yesterday_value;
      }
      Object.assign($scope.summary, summary);
      return $scope.$broadcast("summary", summary);
    });

  $scope.$on("refreshSummary", function(event, data) {
    $scope.refresh();
    return event.stopPropagation();
  });

  $scope.isActive = function() {
    if (!$scope.$parent.currentAccountBook) {
      return;
    }
    return $scope.accountBook.metadata.name === $scope.$parent.currentAccountBook.metadata.name;
  };

  $scope.filter = function(value, index, array) {
    var fnum = Number.parseFloat(value.num)
    if (Number.isNaN(fnum)) {
      fnum = 0
    }
    if ($scope.$parent.options.hideEmptyItems && fnum == 0) {
      return false;
    }
    return true;
  };

  let autoRefresh = $interval(function() {
    if (!$scope.$parent.options.autoRefresh) {
      return;
    }
    if (!$scope.isActive()) {
      return;
    }
    return $scope.refresh();
  }, 3000);

  $scope.$on("$destroy", () => $interval.cancel(autoRefresh));

  if ($scope.isActive()) {
    $scope.refresh();
    $scope.inited = true;
  }

  return $scope.$on('load', function(event, ...args) {
    if ($scope.isActive()) {
      return $scope.refresh();
    }
  });
});
