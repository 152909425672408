angular.module("controllers")

.controller("AccountBookFundController", function($rootScope, $scope, $interval, $uibModal, $filter, $state, $location, $window, transactionService, accountBookService, wsApiService) {
  let accountBookId = $scope.$parent.accountBook.metadata.name;
  $scope.groups = {};
  $scope.summary = {};

  $scope.$on('summary', function(event, ...args) {
    let summary = args[0];
    for (let code in summary.funds) {
      let fundsummary = summary.funds[code];
      if (!(code in $scope.groups)) {
        $scope.groups[code] = {
          funds: []
        };
      }
      let group = $scope.groups[code];
      Object.assign(group, fundsummary);
    }
    return $scope.summary = summary;
  });

  // Used to load items in this group.
  $scope.load = function(group) {
    if (!group.collapsed) {
      return;
    }
    return wsApiService.transactions(accountBookId, "fund", group.code)
      .then(funds => group.funds = funds);
  };

  $scope.create = function(tran_type) {
    let instance = $uibModal.open({
      templateUrl: '/static/templates/add-modal.9f5f5aa2.html',
      controller: 'addController',
      size: 'sm',
      resolve: {
        obj() {
          return {
            spec: {
              type: tran_type,
              time: helpers.toRFC3333DateString(new Date()),
              itemType: "fund",
              bookRef: {
                name: $scope.$parent.accountBook.metadata.name
              }
            }
          };
        }
      }
    });
    return instance.result
      .then((result) => {
        for (let code in $scope.groups) {
          if (code == result.spec.itemCode) {
            $scope.load($scope.groups[code]);
          }
        }
      })
      .finally(() => $scope.$emit("refreshSummary"));
  };

  $scope.update = function(group, fund) {
    let instance = $uibModal.open({
      templateUrl: '/static/templates/add-modal.9f5f5aa2.html',
      controller: 'addController',
      size: 'sm',
      resolve: {
        obj() {
          return fund;
        }
      }
    });
    return instance.result
      .finally(() => $scope.load(group));
  };

  $scope.delete = function(group, fund) {
    bootbox.confirm({
      title: "确定删除?",
      message: `基金代码: ${fund.spec.itemCode} 购买日期：${$filter('date')(fund.spec.time, 'yyyy-MM-dd')}`,
      callback(result) {
        if (!result) {
          return;
        }
        return transactionService.delete(fund.metadata.name)
          .finally(() => $scope.load(group));
      }
    });
  };
  return;
});
