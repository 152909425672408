angular.module("controllers")

.controller('accountBookNewController', function($rootScope, $scope, $uibModalInstance, accountBookService, accountBook) {
  $scope.obj = {}
  if (accountBook) {
    Object.assign($scope.obj, accountBook)
  } else {
    $scope.obj = {
      metadata: {
        name: "",
      },
      spec: {
        type: "normal",
        order: 0,
      },
    }
  }
  if (_.isUndefined($scope.obj.spec.order)) {
    $scope.obj.spec.order = 0;
  }
  $scope.types = [{
    "value": "normal",
    "name": "普通"
  }, {
    "value": "selector",
    "name": "选择器"
  }]
  return $scope.submit = function() {
    if ($scope.obj.metadata.name) {
      return accountBookService.update($scope.obj)
        .then(result => $uibModalInstance.close("success")).catch(err => bootbox.alert(`更新失败 ${err.error}`));
    } else {
      return accountBookService.create($scope.obj)
        .then(result => $uibModalInstance.close("success")).catch(err => bootbox.alert(`创建失败 ${err.error}`));
    }
  };
});
