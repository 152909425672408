angular.module("controllers")

.controller("indexController", function($rootScope, $scope, $log, $timeout, $interval, $uibModal, $filter, $state, $location, $window, userService, accountBookService, wsApiService) {
  $scope.accountBooks = [];
  $scope.options = userService.loadOptions();
  if (!$scope.options) {
    $scope.options = {
      selected_account_book: ""
    };
  }

  $scope.active = 0;
  $scope.currentAccountBook = null;

  wsApiService.info()
    .then(info => $scope.info = info);

  wsApiService.user()
    .then(user => $scope.user = user).catch(err => $state.go('signin'));

  $scope.signout = () =>
    userService.signOut()
    .then(() => $state.go('signin'));

  // Load all books. Activate user selected book if needed. If user does not
  // choose, activiate the first one.
  $scope.load = () =>
    wsApiService.books()
    .then(function(accountBooks) {
      // account books load in two cases:
      // 1. on first page load
      // 2. on account books change
      // So, for rendering page corrrectly, we simply modifed a copy of old objs, then apply at once

      let i, old;
      let copyAccountBooks = $scope.accountBooks.slice();
      let toDeleteAccountBooks = [];

      // delete
      for (var accountBook of Array.from(copyAccountBooks)) {
        old = _.find(accountBooks, a => a.metadata.name === accountBook.metadata.name);
        if (!old) {
          let index = _.findIndex(copyAccountBooks, a => a.metadata.name === accountBook.metadata.name);
          if (index !== 0) {
            toDeleteAccountBooks.push(accountBook);
          }
        }
      }

      // add or update
      for (accountBook of Array.from(accountBooks)) {
        old = _.find(copyAccountBooks, a => a.metadata.name === accountBook.metadata.name);
        if (old) {
          Object.assign(old, accountBook);
        } else {
          copyAccountBooks.push(accountBook);
        }
      }

      // update
      for (accountBook of Array.from(copyAccountBooks)) {
        old = _.find($scope.accountBooks, a => a.metadata.name === accountBook.metadata.name);
        if (old) {
          Object.assign(old, accountBook);
        } else {
          $scope.accountBooks.push(accountBook);
        }
      }
      for (let toDeleteAccountBook of Array.from(toDeleteAccountBooks)) {
        for (i = 0; i < $scope.accountBooks.length; i++) {
          accountBook = $scope.accountBooks[i];
          if (accountBook === toDeleteAccountBook) {
            $scope.accountBooks.splice(i, 1);
            break;
          }
        }
      }

      // sort
      $scope.accountBooks = _.sortBy($scope.accountBooks, a => {
        if (_.isUndefined(a.spec.order)) {
          return 0;
        } else {
          return a.spec.order;
        }
      });
      var active = -1;
      for (i = 0; i < $scope.accountBooks.length; i++) {
        accountBook = $scope.accountBooks[i];
        if (accountBook.metadata.name === $scope.options.selected_account_book) {
          $scope.currentAccountBook = accountBook;
          active = i;
        }
      }
      if (active < 0) {
          active = 0;
          $scope.currentAccountBook = $scope.accountBooks[active]
      }

      // update tabs active index
      // See http://stackoverflow.com/a/36420520/288089.
      return $timeout(() => $scope.active = active, 0);
    });

  $scope.newBook = function() {
    let instance = $uibModal.open({
      templateUrl: '/static/templates/account-book-new-modal.d6d24297.html',
      controller: 'accountBookNewController',
      size: 'md',
      resolve: {
        accountBook() {
          return null;
        }
      }
    });
    return instance.result
      .finally(() => $scope.load());
  };

  $scope.updateBook = function() {
    let accountBook = $scope.currentAccountBook;
    let instance = $uibModal.open({
      templateUrl: '/static/templates/account-book-new-modal.d6d24297.html',
      controller: 'accountBookNewController',
      size: 'md',
      resolve: {
        accountBook() {
          return accountBook;
        }
      }
    });
    return instance.result
      .finally(() => $scope.load());
  };

  $scope.deleteBook = function() {
    let accountBook = $scope.currentAccountBook;
    bootbox.confirm(`确认删除账本 ${accountBook.spec.name}?`, function(result) {
      if (!result) {
        return;
      }
      return accountBookService.delete(accountBook.metadata.name)
        .catch(err => bootbox.alert(`删除失败 ${err.error}`)).finally(() => $scope.load());
    });
  };

  $scope.switch = function(accountBook) {
    if (!accountBook) {
      return;
    }
    $scope.currentAccountBook = accountBook;
    $scope.options.selected_account_book = $scope.currentAccountBook.metadata.name;
    userService.saveOptions($scope.options);
    return $scope.$broadcast("load", accountBook);
  };

  $scope.saveOptions = () => userService.saveOptions($scope.options);

  return $scope.load();
});
