// modules
angular.module('controllers', []);
angular.module('services', []);
angular.module('filters', []);
angular.module('directives', []);

// app
angular.module('app', [
  'ngAria',
  'ngAnimate',
  'ui.router',
  'ui.bootstrap',
  'controllers',
  'services',
  'filters',
  'directives'
])

// routes
.config(function($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false
  });

  $urlRouterProvider.otherwise("/");

  return $stateProvider
    .state('signin', {
      url: '/signin',
      templateUrl: "static/templates/signin.975414e8.html",
      controller: "signinController"
    }).state('index', {
      url: '/',
      templateUrl: "static/templates/index.34be1931.html",
      controller: "indexController"
    });
})

.run(function($rootScope, $state, $log) {
  $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {});
  return $rootScope.$on('$stateChangeSuccess', function(event, toState) {});
});
