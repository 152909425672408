angular.module("controllers")

.controller('addController', function($rootScope, $scope, $uibModalInstance, transactionService, accountBookService, obj) {
  // object
  if (!obj) {
    console.log("no object provided in addController")
    return
  }

  if (!obj.metadata) {
    obj.metadata = {};
  }
  $scope.obj = obj;

  // get list of books
  $scope.books = [];
  accountBookService.list()
    .then(books => {
      for (var book of books) {
        if (book.spec.type != "normal") {
          continue
        }
        $scope.books.push(book)
      }
    });

  $scope.tranTypes = [];
  if (obj.spec.itemType == 'stock' || obj.spec.itemType == 'fund') {
    $scope.tranTypes.push({
      name: "买入",
      value: "buy"
    });
    $scope.tranTypes.push({
      name: "卖出",
      value: "sell"
    });
    $scope.tranTypes.push({
      name: "现金分红",
      value: "cash_dividend"
    });
    if (obj.spec.itemType === 'stock') {
      $scope.tranTypes.push({
        name: "股份分红",
        value: "stock_dividend"
      });
    } else if (obj.spec.itemType === 'fund') {
      $scope.tranTypes.push({
        name: "红利再投资",
        value: "reinvestment_dividend"
      });
    }
  } else if (obj.spec.itemType == 'cash') {
    $scope.tranTypes.push({
      name: "存入",
      value: "deposit"
    });
    $scope.tranTypes.push({
      name: "收益",
      value: "income"
    });
    $scope.tranTypes.push({
      name: "提取",
      value: "withdraw"
    });
  }

  // local variable for input model
  // string: "2018-01-19T02:56:40.000Z"
  // TODO: move these logic into directive, etc
  $scope.time = new Date(obj.spec.time);
  $scope.modelOptions = {
    timezone: "+0800",
  };
  $scope.dateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };
  $scope.format = 'yy/MM/dd HH:mm';
  $scope.status = {
    opened: false
  };
  $scope.open = () => $scope.status.opened = true;
  $scope.disabled = (date, mode) => (mode === 'day') && ((date.getDay() === 0) || (date.getDay() === 6));

  $scope.submit = function() {
    // normalize cash transfter
    if (obj.spec.cashTransfer) {
      if (obj.spec.cashTransfer.bookRef && obj.spec.cashTransfer.bookRef.name && obj.spec.cashTransfer.itemCode) {
        // if book and item code all set
        obj.spec.cashTransfer.itemType = "cash" // hard-coded in web
      } else {
        // invalid, empty it
        obj.spec.cashTransfer = null
      }
    }
    // TODO: move this to directive
    $scope.obj.spec.time = helpers.toRFC3333DateString($scope.time);
    $scope.obj.spec.itemCode = $scope.obj.spec.itemCode.toUpperCase();
    if ($scope.obj.metadata.name) {
      return transactionService.update($scope.obj.metadata.name, obj)
        .then(result => $uibModalInstance.close(result))
        .catch(err => alert("error: " + err.error));
    } else {
      return transactionService.create(obj)
        .then(result => $uibModalInstance.close(result))
        .catch(err => alert("error: " + err.error));
    }
  };

  return;
});
