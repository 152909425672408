angular.module('filters')

.filter('toTimestamp', () => date => Date.parse(date))

.filter('objValuesToArray', () => obj => _.values(obj))

.filter('toPercent', $filter => (input, decimals) => `${$filter('number')(input * 100, decimals)}%`)

.filter('printTranType', () =>
  function(tran_type) {
    switch (tran_type) {
      case "sell":
        return "卖出";
      case "buy":
        return "买入";
      case "cash_dividend":
        return "现金分红";
      case "stock_dividend":
        return "股份分红";
      case "reinvestment_dividend":
        return "红利再投资";
      case "deposit":
        return "存入"
      case "income":
        return "收益"
      case "withdraw":
        return "提取"
      default:
        return tran_type;
    }
  }
)

.filter('printObjType', () =>
  function(obj_type) {
    switch (obj_type) {
      case "stock":
        return "股票";
      case "fund":
        return "基金";
      default:
        return obj_type;
    }
  }
);
