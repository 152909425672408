angular.module('directives')

.directive('accountBookFundDirective', () =>
  ({
    restrict: 'AE',
    templateUrl: '/static/templates/account-book-fund.779b9a1d.html',
    controller: 'AccountBookFundController',
    scope: {}
  })
);
