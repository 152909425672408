angular.module('services')

.factory('accountBookService', function($http, $q) {

  this.list = () =>
    $q((resolve, reject) =>
      $http.get("/api/books")
        .then(resp => resolve(resp.data)
        , resp => reject(resp.data))
    )
  ;

  this.create = function(input) {
    return $q((resolve, reject) =>
      $http.post("/api/books", input)
        .then(resp => resolve(resp.data)
        , resp => reject(resp.data))
    );
  };

  this.update = function(input) {
    return $q((resolve, reject) =>
      $http.put(`/api/books/${input.metadata.name}`, input)
        .then(resp => resolve(resp.data)
        , resp => reject(resp.data))
    );
  };

  this.delete = _id =>
    $q((resolve, reject) =>
      $http.delete(`/api/books/${_id}`)
        .then(resp => resolve(resp.data)
        , resp => reject(resp.data))
    )
  ;

  this.summary = _id =>
    $q((resolve, reject) =>
      $http.get(`/api/books/${_id}/summary`)
        .then(resp => resolve(resp.data)
        , resp => reject(resp.data))
    )
  ;

  return this;
});
